import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UX2, constants as coreConstants, utils } from '@wsb/guac-widget-core';
import {
  dataAids,
  getGalleryDataAid,
  getGalleryDataRouteProps
} from '../../common/constants/dataAids';
import wrapWithDeviceDetection from '../../common/wrapWithDeviceDetection';
import GalleryImage from '../../common/components/GalleryImage';

const { getImageDimensions } = utils.imageDimensions;

const IMGS_PER_PAGE = 6;

export default wrapWithDeviceDetection(
  class Gallery5 extends Component {
    constructor() {
      super(...arguments);
      this.state = {
        page: 1
      };
      this.loadMore = this.loadMore.bind(this);
    }

    static get propTypes() {
      return {
        id: PropTypes.string,
        category: PropTypes.string,
        device: PropTypes.string,
        galleryImages: PropTypes.array.isRequired,
        renderAsThumbnail: PropTypes.bool,
        renderMode: PropTypes.string,
        section: PropTypes.string,
        size: PropTypes.string,
        staticContent: PropTypes.object,
        alignmentOption: PropTypes.string,
        enableImageDimension: PropTypes.bool
      };
    }

    loadMore(e) {
      e.stopPropagation();
      this.setState({ page: this.state.page + 1 });
    }

    renderSeeMoreText() {
      const { staticContent } = this.props;
      const styles = { paddingVertical: 'xlarge', textAlign: 'center' };

      return (
        <UX2.Element.Block style={ styles }>
          <UX2.Element.MoreLink.Expand
            role='button'
            tabIndex='0'
            onClick={ this.loadMore }
            data-aid={ dataAids.GALLERY_MORE_BUTTON }
            children={ staticContent.showMore }
            data-edit-interactive={ true }
          />
        </UX2.Element.Block>
      );
    }

    getImageStyles(alignmentOption) {
      switch (alignmentOption) {
        case 'left':
          return { marginLeft: 0, alignSelf: 'flex-start' };
        case 'center':
          return { marginHorizontal: 'auto', alignSelf: 'center' };
        case 'right':
          return { marginRight: 0, alignSelf: 'flex-end' };
        default:
          return { marginLeft: 0, alignSelf: 'flex-start' };
      }
    }

    renderPages(shouldRenderShorterPreview) {
      const { galleryImages, alignmentOption = 'center', enableImageDimension } = this.props;
      const { page } = this.state;

      const IMGS_TO_RENDER = shouldRenderShorterPreview ? 3 : page * IMGS_PER_PAGE;

      return (
        <UX2.Component.Grid inset={ true }>
          <UX2.Component.Grid.Cell>
            { galleryImages
              .slice(0, IMGS_TO_RENDER)
              .map(({ image, caption, externalLink }, index) => {
                const imageProps = {
                  'imageData': {
                    ...image,
                    enableImageDimension
                  },
                  'data-aid': getGalleryDataAid(index),
                  'style': {
                    ...this.getImageStyles(alignmentOption),
                    width: getImageDimensions(image).outputWidth || 'auto',
                    maxWidth: '100%'
                  }
                };

                return (
                  <UX2.Element.Block
                    style={{ marginBottom: 'small', display: 'flex', flexDirection: 'column' }}
                    key={ index }
                    { ...getGalleryDataRouteProps(index, { isImage: true }) }
                  >
                    <GalleryImage
                      imageProps={ imageProps }
                      externalLink={ externalLink }
                      background={ false }
                    />
                    { caption && (
                      <UX2.Element.Text
                        children={ caption }
                        style={{ textAlign: alignmentOption, marginTop: 'xxsmall' }}
                        data-aid={ dataAids.GALLERY_CAPTION_RENDERED }
                        richtext
                      />
                    ) }
                  </UX2.Element.Block>
                );
              }) }
          </UX2.Component.Grid.Cell>
        </UX2.Component.Grid>
      );
    }

    render() {
      const { galleryImages, renderMode } = this.props;
      const { page } = this.state;
      const pages = Math.ceil(galleryImages.length / IMGS_PER_PAGE);
      const isLayoutView = renderMode === coreConstants.renderModes.DISPLAY;
      const isAddSectionView = renderMode === coreConstants.renderModes.ADD;
      const shouldRenderShorterPreview = isLayoutView || isAddSectionView;

      return (
        <React.Fragment>
          { galleryImages.length ? this.renderPages(shouldRenderShorterPreview) : null }
          { pages > page && !shouldRenderShorterPreview ? this.renderSeeMoreText() : null }
        </React.Fragment>
      );
    }
  }
);
